import { put, call, takeLatest } from "redux-saga/effects";
import request from "utils/request";
import {
  CREATE_RESERVED_SETTING,
  FETCH_RESERVED_SETTING_DETAILS_BY_ID,
  FETCH_RESERVED_TABLES,
  FETCH_RESERVED_TABLES_FOR_UPDATE,
  UPDATE_RESERVED_SETTING,
} from "constant";
import { setError, setLoading, setSuccessMessage } from "actions/common";
import {
  setCreatedReservedSetting,
  setLoadedReservedSettingDetails,
  setReservedSettingTables,
} from "actions/dianpingSeatSetting";

function* createSetting({ data }) {
  const url = "slots/create";
  const options = {
    data: data,
    method: "POST",
  };
  try {
    yield call(request, url, options, false);
    yield put(setSuccessMessage("登録しました。"));
    yield put(setCreatedReservedSetting(true));
  } catch (e) {
    yield put(setCreatedReservedSetting(null));
    yield put(setError(e.toString()));
    yield put(setLoading(false));
  }
}

function* loadTables({ storeId }) {
  if (parseInt(storeId) > 0) {
    const url = "slots/getAllTableByStoreId/" + storeId;
    const options = {
      method: "GET",
    };

    try {
      const response = yield call(request, url, options, false);
      yield put(setReservedSettingTables(response));
      yield put(setLoading(false));
    } catch (e) {
      yield put(setReservedSettingTables(null));
      yield put(setError(e.toString()));
      yield put(setLoading(false));
    }
  }
}

function* loadTablesForUpdate({ slotId, storeId }) {
  if (parseInt(storeId) > 0) {
    const url = `slots/getAllTableByStoreIdForUpdate?storeId=${storeId}&slotId=${slotId}`;
    const options = {
      method: "GET",
    };

    try {
      const response = yield call(request, url, options, false);
      yield put(setReservedSettingTables(response));
      yield put(setLoading(false));
    } catch (e) {
      yield put(setReservedSettingTables(null));
      yield put(setError(e.toString()));
      yield put(setLoading(false));
    }
  }
}

function* loadSettingDetails({ id }) {
  const url = "slots/getSlotById/" + id;
  const options = {
    method: "GET",
  };

  try {
    const result = yield call(request, url, options, false);
    if (!result.data) {
      yield put(setError(result.message));
      yield put(setCreatedReservedSetting(true));
    } else {
      yield put(setLoadedReservedSettingDetails(result.data));
      yield put(setLoading(false));
    }
  } catch (e) {
    yield put(setError(e.toString()));
    yield put(setLoading(false));
    yield put(setCreatedReservedSetting(true));
  }
}

function* updateSetting({ data }) {
  const url = "slots/edit";
  const options = {
    data: data,
    method: "PUT",
  };
  try {
    yield call(request, url, options, false);
    yield put(setSuccessMessage("更新しました。"));
    yield put(setCreatedReservedSetting(true));
  } catch (e) {
    yield put(setCreatedReservedSetting(null));
    yield put(setError(e.toString()));
    yield put(setLoading(false));
  }
}

export function* dianpingReservedSettingCreateUpdateWatcher() {
  yield takeLatest(CREATE_RESERVED_SETTING, createSetting);
  yield takeLatest(FETCH_RESERVED_TABLES, loadTables);
  yield takeLatest(FETCH_RESERVED_TABLES_FOR_UPDATE, loadTablesForUpdate);
  yield takeLatest(FETCH_RESERVED_SETTING_DETAILS_BY_ID, loadSettingDetails);
  yield takeLatest(UPDATE_RESERVED_SETTING, updateSetting);
}
