import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { Row, Col, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import FormHeader from "components/FormHeader/index";
import Layout from "containers/Layout";
import DataSidePreview from "components/DataSidePreview";
import SettingsIcon from "components/Icons/SettingsIcon";
import { setError, setLoading } from "actions/common";
import { setLoadedMenuDetails } from "actions/menu";
import DianpingReservedSettingForm from "../../components/Form/DianpingReservedSettingForm";
import {
  createReservedSetting,
  deleteReservedSetting,
  fetchReservedSettingDetailsById,
  fetchReservedSettingTables,
  fetchReservedSettingTablesForUpdate,
  setCreatedReservedSetting,
  setLoadedReservedSettingDetails,
  setReservedSettingTables,
  updateReservedSetting,
} from "../../actions/dianpingSeatSetting";

const { confirm } = Modal;

export default function DianpingReservedSetingCreateUpdate() {
  const history = useHistory();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    shouldUnregister: false,
  });

  const { handleSubmit, setValue, control, watch } = methods;
  const { id } = useParams();
  const isEditing = !!id;
  // useSelector
  const created = useSelector(
    (state) => state.dianpingReservedSettingCreateUpdateReducer.isCreatedSetting
  );
  const stores = useSelector((state) => state.layoutReducer.stores);
  const selectedStore = useSelector(
    (state) => state.layoutReducer.selectedStore
  );
  const loadedSettingDetails = useSelector(
    (state) =>
      state.dianpingReservedSettingCreateUpdateReducer.loadedSettingDetails
  );
  const tableList = useSelector(
    (state) =>
      state.dianpingReservedSettingCreateUpdateReducer.reservedSettingTables
  );
  // useState
  const selectedTables = watch("selectedTables");
  // useEffect
  useEffect(() => {
    // load detail
    if (isEditing) {
      dispatch(setLoading(true));
      if (!loadedSettingDetails) {
        dispatch(fetchReservedSettingDetailsById(id));
      }
    }
  }, []);

  const selectableTables = useMemo(() => {
    if (tableList && tableList.data) {
      return formatData(tableList.data);
    }
    return [];
  }, [tableList]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setReservedSettingTables(null));
    loadTables();
  }, [selectedStore.id]);

  useEffect(() => {
    if (isEditing && loadedSettingDetails) {
      // 店舗管理名称 - name
      setValue("name", loadedSettingDetails.name);
      // 対象テーブル - target tables
      if (loadedSettingDetails.tables) {
        setValue("selectedTables", loadedSettingDetails.tables);
      }
      // 受付可能人数 - number of customer
      setValue("minNumberOfCustomer", loadedSettingDetails.minNumberOfCustomer);
      setValue("maxNumberOfCustomer", loadedSettingDetails.maxNumberOfCustomer);
      // 予約受付設定 - reception settings
      setValue(
        "isReceiving",
        loadedSettingDetails.isReceiving === true ? "true" : "false"
      );
      dispatch(setLoadedReservedSettingDetails(null));
    }
  }, [loadedSettingDetails]);

  useEffect(() => {
    if (created) {
      history.push("/settings/dianping-reserved-seat-setting");
      dispatch(setCreatedReservedSetting(false));
    }
  }, [created]);

  useEffect(() => {
    if (stores && loadedSettingDetails) {
      dispatch(setLoadedMenuDetails(null));
      dispatch(setLoading(false));
    }
  }, [stores]);
  const loadTables = () => {
    if (isEditing) {
      dispatch(fetchReservedSettingTablesForUpdate(id, selectedStore.id));
    } else {
      dispatch(fetchReservedSettingTables(selectedStore.id));
    }
  };
  // Format data
  function formatData(data) {
    const newData = [];
    data.forEach((row, index) => {
      newData.push({
        key: row.tableItemId,
        id: row.tableItemId,
        index: index,
        ...row,
      });
    });
    return newData;
  }
  // Handlers
  const onCancelHandler = () => {
    if (isEditing) {
      confirm({
        icon: <ExclamationCircleOutlined />,
        title: "確認",
        content: "編集した内容は破棄されます。よろしいですか？",
        okText: "はい",
        okType: "danger",
        cancelText: "いいえ",
        centered: true,
        onOk() {
          history.push("/settings/dianping-reserved-seat-setting");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      history.push("/settings/dianping-reserved-seat-setting");
    }
  };
  const deleteReservedSettingById = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "確認",
      content:
        "削除したデータはもとに戻せません。予約席を削除してもよろしいですか？",
      okText: "はい",
      okType: "danger",
      cancelText: "いいえ",
      centered: true,
      onOk() {
        dispatch(setLoading(true));
        dispatch(deleteReservedSetting(id, selectedStore.id));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onSubmit = (data) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "確認",
      content: isEditing
        ? "更新します。よろしいですか？"
        : "登録します。よろしいですか？",
      okText: "はい",
      okType: "danger",
      cancelText: "いいえ",
      centered: true,
      onOk() {
        handleOkSubmit(data);
      },
      onCancel() {
        return false;
      },
    });
  };

  const handleOkSubmit = (data) => {
    if (data.name === "") {
      dispatch(setError("店舗管理名称を入力してください。"));
      return false;
    }
    if (isNaN(selectedStore.id)) {
      dispatch(setError("店舗情報を登録してください。"));
      return false;
    }
    // validate table
    const selectedTables = data.selectedTables ?? [];
    if (selectedTables.length === 0) {
      dispatch(setError("対象テーブルを選択してください。"));
      return false;
    }
    // validate number of customer
    const minCustomer = parseInt(data.minNumberOfCustomer);
    const maxCustomer = parseInt(data.maxNumberOfCustomer);
    if (
      isNaN(minCustomer) ||
      isNaN(maxCustomer) ||
      minCustomer === 0 ||
      maxCustomer === 0
    ) {
      dispatch(setError("受付可能人数を入力してください。"));
      return false;
    }
    if (
      !isNaN(minCustomer) &&
      !isNaN(maxCustomer) &&
      minCustomer > maxCustomer
    ) {
      dispatch(setError("受付可能人数を正しく入力してください。"));
      return false;
    }
    data.isReceiving = data.isReceiving === "true";
    data.storeId = selectedStore.id;
    data.minNumberOfCustomer = !isNaN(minCustomer) ? minCustomer : null;
    data.maxNumberOfCustomer = !isNaN(maxCustomer) ? maxCustomer : null;
    data.tables = selectedTables;
    delete data.selectedTables;
    dispatch(setLoading(true));
    if (isEditing) {
      data.id = id;
      dispatch(updateReservedSetting(data));
    } else dispatch(createReservedSetting(data));
  };
  // Preview data at right sidebar
  const dataPreview = [
    {
      heading: "予約席",
      items: [
        {
          label: "店舗管理名称",
          value: (watcher) => {
            const name = watcher.name ? `${watcher.name}` : "";
            return name || "";
          },
        },
        {
          label: "対象テーブル ",
          value: (watcher) => {
            const tables = watcher.selectedTables ? watcher.selectedTables : [];
            return tables.map((item) => item.name).join(", ");
          },
        },
        {
          label: "受付可能人数",
          value: (watcher) => {
            const min = parseInt(watcher.minNumberOfCustomer);
            const max = parseInt(watcher.maxNumberOfCustomer);
            if (isNaN(min) || isNaN(max) || min === 0 || max === 0) {
              return "";
            }
            if (min === max) {
              return `${min}名`;
            }
            return `${min} ～ ${max}名`;
          },
        },
        {
          label: "予約受付設定",
          value: (watcher) => {
            const isReceiving =
              watcher.isReceiving && watcher.isReceiving === "false"
                ? "受け付けない"
                : "受け付ける";
            return isReceiving || "";
          },
        },
      ],
    },
  ];
  return (
    <Layout>
      <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
        <FormHeader
          title={isEditing ? "予約席の編集" : "予約席の新規登録"}
          icon={<SettingsIcon width={"28"} height={"28"} />}
        />
        <Row wrap={false}>
          <Col flex="auto">
            <DianpingReservedSettingForm
              control={control}
              isEdit={isEditing}
              tables={selectableTables}
              onChangeTables={(tables) => {
                setValue("selectedTables", tables);
              }}
              defaultSelectedTables={selectedTables}
            />
          </Col>
          <DataSidePreview
            data={dataPreview}
            control={control}
            title={"大衆点評予約席"}
            submitButtonTitle={isEditing ? "更新する" : "登録する"}
            onCancel={onCancelHandler}
            isEdit={isEditing}
            deleteHandler={deleteReservedSettingById}
          />
        </Row>
      </form>
    </Layout>
  );
}
