import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_RESERVED_SETTING,
  FETCH_ALL_RESERVED_SETTING_LIST,
  RE_ORDERED_RESERVED_SETTING_LIST,
} from "constant";
import request from "utils/request";
import { setError, setLoading, setSuccessMessage } from "actions/common";
import {
  fetchAllReservedSettingList,
  setAllReservedSettingList,
  setCreatedReservedSetting,
} from "actions/dianpingSeatSetting";

function* getAllDianpingReservedSettingByStoreId({ storeId }) {
  if (parseInt(storeId) > 0) {
    const url = `slots/getAllSlotByStoreId/${storeId}`;
    const options = {
      method: "GET",
    };

    try {
      const response = yield call(request, url, options, false);
      yield put(setAllReservedSettingList(response));
      yield put(setLoading(false));
    } catch (e) {
      yield put(setAllReservedSettingList(null));
      yield put(setError(e.toString()));
      yield put(setLoading(false));
    }
  }
}

function* reOrderReservedSettingList({ reOrderedData, storeId }) {
  const url = "slots/changeOrder";
  const options = {
    method: "PUT",
    data: reOrderedData,
  };
  try {
    yield call(request, url, options, false);
    yield put(setSuccessMessage("表示順序が正常に更新されました"));
    yield put(setLoading(false));
  } catch (e) {
    yield put(setError(e.toString()));
    yield put(fetchAllReservedSettingList(storeId));
    yield put(setLoading(false));
  }
}

function* deleteReservedSetting({ id, storeId }) {
  const url = "slots/delete/" + id;
  const options = {
    method: "DELETE",
  };
  try {
    yield call(request, url, options, false);
    yield put(fetchAllReservedSettingList(storeId));
    yield put(setSuccessMessage("削除しました。"));
    yield put(setCreatedReservedSetting(true));
  } catch (e) {
    yield put(setLoading(false));
    yield put(setError(e.toString()));
    yield put(setCreatedReservedSetting(true));
  }
}

export function* dianpingReservedSettingWatcher() {
  yield takeLatest(
    FETCH_ALL_RESERVED_SETTING_LIST,
    getAllDianpingReservedSettingByStoreId
  );
  yield takeLatest(
    RE_ORDERED_RESERVED_SETTING_LIST,
    reOrderReservedSettingList
  );
  yield takeLatest(DELETE_RESERVED_SETTING, deleteReservedSetting);
}
