export const LOGIN = "LOGIN";
export const SET_LOGIN = "SET_LOGIN";
export const FETCH_ACCOUNT_PROFILE = "FETCH_ACCOUNT_PROFILE";
export const SET_ACCOUNT_PROFILE = "SET_ACCOUNT_PROFILE";

export const STROKE = "#121958";
export const GET_NOTIFY_MESSAGE_PAGING = "GET_NOTIFY_MESSAGE_PAGING";
export const SET_NOTIFY_MESSAGE_PAGING = "SET_NOTIFY_MESSAGE_PAGING";
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE";
export const TOGGLE_BACKDROP = "TOGGLE_BACKDROP";
export const SET_LOADING = "SET_LOADING";
export const SET_SELECTED_STORE = "SET_SELECTED_STORE";
export const GET_SELECTED_STORE = "GET_SELECTED_STORE";
export const SET_STORES = "SET_STORES";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_IPHONE = "SET_IPHONE";
export const SET_SHOW_SECOND_HEADER = "SET_SHOW_SECOND_HEADER";

export const SET_RESERVATION_DATA = "SET_RESERVATION_DATA";
export const SET_RESERVATION_COLUMN = "SET_RESERVATION_COLUMN";
export const FETCH_RESERVATION_DATA = "FETCH_RESERVATION_DATA";
export const FETCH_NET_RESERVATION_UNCONFIRMED_BY_STOREID =
  "FETCH_NET_RESERVATION_UNCONFIRMED_BY_STOREID";
export const SET_NET_RESERVATION_UNCONFIRMED_BY_STOREID =
  "SET_NET_RESERVATION_UNCONFIRMED_BY_STOREID";

export const FETCH_CUSTOMER_CUSTOM_ITEM_ORDER =
  "FETCH_CUSTOMER_CUSTOM_ITEM_ORDER";
export const SET_CUSTOMER_CUSTOM_ITEM_ORDER = "SET_CUSTOMER_CUSTOM_ITEM_ORDER";
export const FETCH_CUSTOM_ITEM_ORDER = "FETCH_CUSTOM_ITEM_ORDER";
export const SET_CUSTOM_ITEM_ORDER = "SET_CUSTOM_ITEM_ORDER";
export const SUBMIT_CUSTOMER_FORM = "SUBMIT_CUSTOMER_FORM";
export const UPDATE_CUSTOMER_FORM = "UPDATE_CUSTOMER_FORM";
export const SET_CUSTOMER_FORM = "SET_CUSTOMER_FORM";

export const FETCH_CUSTOMER_HISTORY = "FETCH_CUSTOMER_HISTORY";
export const SET_CUSTOMER_HISTORY = "SET_CUSTOMER_HISTORY";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

export const FETCH_STORE_BY_UUID = "GET_STORE_UUID";
export const SET_STORE_UUID = "SET_STORE_UUID";

// Common Layout Action constants
export const SET_ERROR = "SET_ERROR";
export const GET_ERROR = "GET_ERROR";
export const SHOP_INFO_ERROR = "店舗情報を登録してください。";

// Dashboard Action Constants
export const FETCH_RESERVATION_DATA_DAY = "FETCH_RESERVATION_DATA_DAY";
export const SET_RESERVATION_DATA_DAY = "SET_RESERVATION_DATA_DAY";

export const FETCH_RESERVATION_DATA_WEEK = "FETCH_RESERVATION_DATA_WEEK";
export const SET_RESERVATION_DATA_WEEK = "SET_RESERVATION_DATA_WEEK";

export const FETCH_RESERVATION_DATA_MONTH = "FETCH_RESERVATION_DATA_MONTH";
export const SET_RESERVATION_DATA_MONTH = "SET_RESERVATION_DATA_MONTH";

export const FETCH_RESERVATION_DETAILS = "FETCH_RESERVATION_DETAILS";
export const SET_RESERVATION_DETAILS = "SET_RESERVATION_DETAILS";
export const DELETE_RESERVATION_DETAILS = "DELETE_RESERVATION_DETAILS";
export const CHANGE_RESERVATION_STATUS = "CHANGE_RESERVATION_STATUS";
export const UPDATE_RESERVATION_TRACK_STATUS =
  "UPDATE_RESERVATION_TRACK_STATUS";
export const SET_IS_DELETED = "SET_DELETED";
export const DELETE_NOTIFY_MESSAGES_BY_RESERVATION_ID =
  "DELETE_NOTIFY_MESSAGES_BY_RESERVATION_ID";
export const SET_CURRENT_GANTT_VIEW = "SET_CURRENT_GANTT_VIEW";

// Store master constants
export const FETCH_STORE_DATA = "FETCH_STORE_DATA";
export const FETCH_ALL_STORE_DETAIL_BY_ACCOUNT =
  "FETCH_ALL_STORE_DETAIL_BY_ACCOUNT";
export const SET_ALL_STORE_DETAIL_BY_ACCOUNT =
  "SET_ALL_STORE_DETAIL_BY_ACCOUNT";
export const RE_ORDERED_STORES = "RE_ORDERED_STORES";
export const DELETE_STORE = "DELETE_STORE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const GET_SUCCESS_MESSAGE = "GET_SUCCESS_MESSAGE";
export const SET_MAX_STORE = "SET_MAX_STORE";
export const GET_MAX_STORE = "GET_MAX_STORE";

// Customer constants
export const FETCH_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const SET_CUSTOMER_LIST = "SET_ALL_CUSTOMER_BY_STORE_WITH_PAGING";
export const FETCH_CUSTOMER_BY_CHARACTER = "GET_CUSTOMER_BY_CHARACTER";
export const GET_CUSTOMER_ADVANCE_SEARCH = "GET_CUSTOMER_ADVANCE_SEARCH";
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const SET_DELETED_CUSTOMER = "SET_DELETED_CUSTOMER";
export const SET_DELETED_CUSTOMER_DETAIL = "SET_DELETED_CUSTOMER_DETAIL";

export const SET_POSTAL_CODE_DATA = "SET_POSTAL_CODE_DATA";
export const FETCH_POSTAL_CODE_DATA = "FETCH_POSTAL_CODE_DATA";
export const GENERATE_UUID = "GENERATE_UUID";
export const SET_UUID = "SET_UUID";
export const CREATE_STORE = "CREATE_STORE";
export const IS_CREATED_STORE = "IS_CREATED_STORE";
export const FETCH_BY_ZIP_CODE = "FETCH_BY_ZIP_CODE";
export const FETCH_STORE_BY_ID = "FETCH_STORE_BY_ID";
export const SET_LOADED_STORE = "SET_LOADED_STORE";
export const EDIT_STORE = "EDIT_STORE";

export const FETCH_CUSTOMER_STATUS = "FETCH_CUSTOMER_STATUS";
export const SET_CUSTOMER_STATUS = "SET_CUSTOMER_STATUS";

// Create Reservation Constants

export const FETCH_CUSTOMER_BY_PHONE = "FETCH_CUSTOMER_BY_PHONE";
export const SET_CUSTOMER_SUGGESTION = "SET_CUSTOMER_SUGGESTION";
export const FETCH_TABLE_LIST = "FETCH_TABLE_LIST";
export const SET_TABLE_LIST = "SET_TABLE_LIST";
export const FETCH_MENU_LIST = "FETCH_MENU_LIST";
export const SET_MENU_LIST = "SET_MENU_LIST";
export const FETCH_RECEPTIONIST = "FETCH_RECEPTIONIST";
export const SET_RECEPTIONIST = "SET_RECEPTIONIST";
export const FETCH_RESERVATION_METHOD = "FETCH_RESERVATION_METHOD";
export const SET_RESERVATION_METHOD = "SET_RESERVATION_METHOD";
export const CREATE_RESERVATION = "CREATE_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const SET_RESERVATION = "SET_RESERVATION";
export const SEND_SMS_RESERVATION_MESSAGE = "SEND_SMS_RESERVATION_MESSAGE";
export const CONFIRM_NET_RESERVATION = "CONFIRM_NET_RESERVATION";
export const CREATE_RPA_RESERVATION = "CREATE_RPA_RESERVATION";

// List Reservation Constants

export const FETCH_ALL_BY_DAY_WITH_PAGING = "FETCH_ALL_BY_DAY_WITH_PAGING";
export const SET_ALL_BY_DAY_WITH_PAGING = "SET_ALL_BY_DAY_WITH_PAGING";
export const FETCH_BY_NAME_OR_SPELLING_OR_PHONE =
  "FETCH_BY_NAME_OR_SPELLING_OR_PHONE";
export const SET_BY_NAME_OR_SPELLING_OR_PHONE =
  "SET_BY_NAME_OR_SPELLING_OR_PHONE";

// Account Master Constants
export const FETCH_ALL_ACCOUNT_LIST = "FETCH_ALL_ACCOUNT_LIST";
export const SET_ALL_ACCOUNT_LIST = "SET_ALL_ACCOUNT_LIST";
export const RE_ORDERED_ACCOUNTS = "RE_ORDERED_ACCOUNTS";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const CREATE_ACCOUNT_USER = "CREATE_ACCOUNT_USER";
export const SET_IS_CREATED_ACCOUNT = "SET_IS_CREATED_ACCOUNT";
export const FETCH_ACCOUNT_DETAILS_BY_USER_ID =
  "FETCH_ACCOUNT_DETAILS_BY_USER_ID";
export const SET_LOADED_ACCOUNT_DETAILS = "SET_LOADED_ACCOUNT_DETAILS";
export const UPDATE_ACCOUNT_USER = "UPDATE_ACCOUNT_USER";

// Staff Store Master Constants
export const FETCH_ALL_STAFF_STORES_BY_STORE_ID =
  "FETCH_ALL_STAFF_STORES_BY_STORE_ID";
export const SET_ALL_STAFF_STORES = "SET_ALL_STAFF_STORES";
export const RE_ORDERED_STAFF_STORES = "RE_ORDERED_STAFF_STORES";
export const DELETE_STAFF_STORE = "DELETE_STAFF_STORE";
export const CREATE_STAFF_STORE = "CREATE_STAFF_STORE";
export const UPDATE_STAFF_STORE = "UPDATE_STAFF_STORE";
export const SET_IS_CREATED_STAFF_STORE = "SET_IS_CREATED_STAFF_STORE";
export const FETCH_STAFF_STORE_DETAILS_BY_ID =
  "FETCH_STAFF_STORE_DETAILS_BY_ID";
export const SET_LOADED_STAFF_STORE_DETAILS = "SET_LOADED_STAFF_STORE_DETAILS";

// Custom Items Constants
// export const FETCH_CUSTOM_ITEMS = "FETCH_CUSTOM_ITEMS";
// export const RE_ORDERED_CUSTOM_ITEMS = "RE_ORDERED_CUSTOM_ITEMS";
// export const CREATE_CUSTOM_ITEMS = "CREATE_CUSTOM_ITEMS";
// export const SET_IS_CREATED_ITEMS = "SET_IS_CREATED_ITEMS";
// export const UPDATE_CUSTOM_ITEMS = "UPDATE_CUSTOM_ITEMS";
// export const FETCH_CUSTOM_ITEMS_DETAILS = "FETCH_CUSTOM_ITEMS_DETAILS";
// export const SET_LOADED_ITEMS_DETAILS = "SET_LOADED_ITEMS_DETAILS";
export const FETCH_CUSTOM_ITEMS = "FETCH_CUSTOM_ITEMS";
export const SET_CUSTOM_ITEMS = "SET_CUSTOM_ITEMS";
export const DELETE_CUSTOM_ITEM = "DELETE_CUSTOM_ITEM";
export const RE_ORDERED_CUSTOM_ITEMS = "RE_ORDERED_CUSTOM_ITEMS";
export const RE_ORDERED_CUSTOMER_CUSTOM_FIELD =
  "RE_ORDERED_CUSTOMER_CUSTOM_FIELD";

export const CREATE_CUSTOM_ITEMS = "CREATE_CUSTOM_ITEMS";
export const SET_IS_CREATED_ITEMS = "SET_IS_CREATED_ITEMS";
export const UPDATE_CUSTOM_ITEMS = "UPDATE_CUSTOM_ITEMS";
export const FETCH_CUSTOM_ITEMS_DETAILS = "FETCH_CUSTOM_ITEMS_DETAILS";
export const SET_LOADED_ITEMS_DETAILS = "SET_LOADED_ITEMS_DETAILS";

// Menu master constants
export const FETCH_ALL_MENU_LIST = "FETCH_ALL_MENU_LIST";
export const SET_ALL_MENU_LIST = "SET_ALL_MENU_LIST";
export const RE_ORDERED_MENUS = "RE_ORDERED_MENUS";
export const DELETE_MENU = "DELETE_MENU";
export const SET_CREATED_MENU = "SET_CREATED_MENU";

export const CREATE_MENU = "CREATE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const FETCH_MENU_DETAILS_BY_ID = "FETCH_MENU_DETAILS_BY_ID";
export const SET_LOADED_MENU_DETAILS = "SET_LOADED_MENU_DETAILS";

// Table master constants
export const FETCH_ALL_TABLES_BY_ACCOUNT = "FETCH_ALL_TABLES_BY_ACCOUNT";
export const SET_ALL_TABLES_BY_ACCOUNT = "SET_ALL_TABLES_BY_ACCOUNT";
export const RE_ORDERED_TABLES = "RE_ORDERED_TABLES";
export const DELETE_TABLE = "DELETE_TABLE";
export const CREATE_TABLE = "CREATE_TABLE";
export const SET_IS_CREATED_TABLE = "SET_IS_CREATED_TABLE";
export const FETCH_TABLE_DETAILS_BY_ID = "FETCH_TABLE_DETAILS_BY_ID";
export const SET_LOADED_TABLE_DETAILS = "SET_LOADED_TABLE_DETAILS";
export const UPDATE_TABLE = "UPDATE_TABLE";

// Rserved route setting constants
export const FETCH_ALL_RESERVED_ROUTE_BY_STORE_ID =
  "FETCH_ALL_RESERVED_ROUTE_BY_STORE_ID";
export const SET_ALL_RESERVED_ROUTE = "SET_ALL_RESERVED_ROUTE";
export const RE_ORDERED_RESERVED_ROUTE = "RE_ORDERED_RESERVED_ROUTE";
export const DELETE_RESERVED_ROUTE = "DELETE_RESERVED_ROUTE";

export const CREATE_RESERVED_ROUTE = "CREATE_RESERVED_ROUTE";
export const SET_IS_CREATED_RESERVED_ROUTE = "SET_IS_CREATED_RESERVED_ROUTE";
export const FETCH_RESERVED_ROUTE_DETAILS_BY_ID =
  "FETCH_RESERVED_ROUTE_DETAILS_BY_ID";
export const SET_RESERVED_ROUTE_DETAILS = "SET_RESERVED_ROUTE_DETAILS";
export const UPDATE_RESERVED_ROUTE = "UPDATE_RESERVED_ROUTE";

// Line configuration constants
export const CREATE_LINE_CONFIG = "CREATE_LINE_CONFIG";
export const IS_CREATED_LINE_CONFIG = "IS_CREATED_LINE_CONFIG";
export const FETCH_LINE_CONFIG_BY_STORE_ID = "FETCH_LINE_CONFIG_BY_STORE_ID";
export const SET_LINE_CONFIG_BY_STORE_ID = "SET_LINE_CONFIG_BY_STORE_ID";
export const FETCH_LINE_BOT_INFO = "FETCH_LINE_BOT_INFO";
export const SET_LINE_BOT_INFO = "SET_LINE_BOT_INFO";
export const DELETE_LINE_CONFIG = "DELETE_LINE_CONFIG";
export const FETCH_WEBHOOK_INFO = "FETCH_WEBHOOK_INFO";
export const SET_WEBHOOK_INFO = "SET_WEBHOOK_INFO";

// Message constants
export const CREATE_MESSAGE = "CREATE_MESSAGE";

// Net Reservations

export const CREATE_NET_RESERVATION = "CREATE_NET_RESERVATION";
export const SET_NET_CREATED = "SET_NET_CREATED";

export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS";
export const SET_HOLIDAYS = "SET_HOLIDAYS";

export const FETCH_STORE_DETAILS_BY_UUID = "FETCH_STORE_DETAILS_BY_UUID";
export const SET_STORE_DETAILS = "SET_STORE_DETAILS";

export const FETCH_TABLE_AVAILABLE_TIME = "FETCH_TABLE_AVAILABLE_TIME";
export const FETCH_TABLE_ONLINE_AVAILABLE_TIME =
  "FETCH_TABLE_ONLINE_AVAILABLE_TIME";
export const SET_TABLE_AVAILABLE_TIME = "SET_TABLE_AVAILABLE_TIME";
export const SET_TABLE_ONLINE_AVAILABLE_TIME =
  "SET_TABLE_ONLINE_AVAILABLE_TIME";

export const DELETE_NOTIFICATION_BY_ID = "DELETE_NOTIFICATION_BY_ID";
export const UPDATE_NOTIFICATION_BY_ID = "UPDATE_NOTIFICATION_BY_ID";
export const UPDATE_NOTIFICATION_OLD = "UPDATE_NOTIFICATION_OLD";
// Email Config
export const SET_HOST_MAIL_CONFIG_TYPE = "SET_HOST_MAIL_CONFIG_TYPE";
export const FETCH_ALL_MAIL_CONFIG = "FETCH_ALL_MAIL_CONFIG";
export const SET_ALL_MAIL_STATUS = "SET_ALL_MAIL_STATUS";
export const CREATE_GNAVI_SUCCESS = "CREATE_GNAVI_SUCCESS";
export const RESET_CREATE_GNAVI_SUCCESS = "RESET_CREATE_GNAVI_SUCCESS";
export const CONFIG_GNAVI_MAIL = "CONFIG_GNAVI_MAIL";
export const CREATE_TABELOG_SUCCESS = "CREATE_TABELOG_SUCCESS";
export const RESET_CREATE_TABELOG_SUCCESS = "RESET_CREATE_TABELOG_SUCCESS";
export const CONFIG_TABELOG_MAIL = "CONFIG_TABELOG_MAIL";
export const CREATE_HOTPEPPER_SUCCESS = "CREATE_HOTPEPPER_SUCCESS";
export const RESET_CREATE_HOTPEPPER_SUCCESS = "RESET_CREATE_HOTPEPPER_SUCCESS";
export const CONFIG_HOTPEPPER_MAIL = "CONFIG_HOTPEPPER_MAIL";
export const DELETE_MAIL_CONFIG = "DELETE_MAIL_CONFIG";
export const FETCH_AWS_CONFIG = "FETCH_AWS_CONFIG";
export const SET_AWS_CONFIG = "SET_AWS_CONFIG";
// Dianping Reserved Seat Setting constants
export const FETCH_ALL_RESERVED_SETTING_LIST =
  "FETCH_ALL_RESERVED_SETTING_LIST";
export const SET_ALL_RESERVED_SETTING_LIST = "SET_ALL_RESERVED_SETTING_LIST";
export const RE_ORDERED_RESERVED_SETTING_LIST =
  "RE_ORDERED_RESERVED_SETTING_LIST";
export const DELETE_RESERVED_SETTING = "DELETE_RESERVED_SETTING";
export const SET_CREATED_RESERVED_SETTING = "SET_CREATED_RESERVED_SETTING";

export const CREATE_RESERVED_SETTING = "CREATE_RESERVED_SETTING";
export const UPDATE_RESERVED_SETTING = "UPDATE_RESERVED_SETTING";
export const FETCH_RESERVED_SETTING_DETAILS_BY_ID =
  "FETCH_RESERVED_SETTING_DETAILS_BY_ID";
export const SET_LOADED_RESERVED_SETTING_DETAILS =
  "SET_LOADED_RESERVED_SETTING_DETAILS";
export const FETCH_RESERVED_TABLES = "FETCH_RESERVED_TABLES";
export const FETCH_RESERVED_TABLES_FOR_UPDATE =
  "FETCH_RESERVED_TABLES_FOR_UPDATE";
export const SET_RESERVED_SETTING_TABLES = "SET_RESERVED_SETTING_TABLES";

// Reservation conditions
export const RESERVATION_CONDITIONS = [
  {
    value: 1,
    label: "リクエスト予約（お店が承認するまで予約は確定されません）",
    previewText: "リクエスト予約",
  },
  {
    value: 2,
    label: "即予約（お客様が予約をした段階で予約が確定します）",
    previewText: "即予約",
  },
];

// Link Dianping - Stock unit
export const DIANPING_STOCKUNITS_MODES = {
  TIME: "1",
  DAY: "2",
};
export const DIANPING_STOCKUNITS = [
  {
    value: DIANPING_STOCKUNITS_MODES.TIME,
    label: "時間",
  },
  {
    value: DIANPING_STOCKUNITS_MODES.DAY,
    label: "日",
  },
];

// Link Dianping - Number people
export const COMMON_SELECTION_OPTIONS = {
  YES: "1",
  NO: "2",
};
export const DIANPING_MANAGE_BY_PEOPLE = [
  {
    value: COMMON_SELECTION_OPTIONS.YES,
    label: "する",
  },
  {
    value: COMMON_SELECTION_OPTIONS.NO,
    label: "しない",
  },
];
