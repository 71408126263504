import {
  DELETE_RESERVED_SETTING,
  CREATE_RESERVED_SETTING,
  FETCH_ALL_RESERVED_SETTING_LIST,
  RE_ORDERED_RESERVED_SETTING_LIST,
  SET_ALL_RESERVED_SETTING_LIST,
  SET_CREATED_RESERVED_SETTING,
  UPDATE_RESERVED_SETTING,
  FETCH_RESERVED_SETTING_DETAILS_BY_ID,
  SET_LOADED_RESERVED_SETTING_DETAILS,
  FETCH_RESERVED_TABLES,
  SET_RESERVED_SETTING_TABLES,
  FETCH_RESERVED_TABLES_FOR_UPDATE,
} from "constant";

export function fetchAllReservedSettingList(storeId) {
  return {
    type: FETCH_ALL_RESERVED_SETTING_LIST,
    storeId,
  };
}

export function setAllReservedSettingList(payload) {
  return {
    type: SET_ALL_RESERVED_SETTING_LIST,
    payload,
  };
}

export function reOrderReservedSettingList(reOrderedData, storeId) {
  return {
    type: RE_ORDERED_RESERVED_SETTING_LIST,
    reOrderedData,
    storeId,
  };
}

export function deleteReservedSetting(id, storeId) {
  return {
    type: DELETE_RESERVED_SETTING,
    id,
    storeId,
  };
}

export function createReservedSetting(data) {
  return {
    type: CREATE_RESERVED_SETTING,
    data,
  };
}

export function updateReservedSetting(data) {
  return {
    type: UPDATE_RESERVED_SETTING,
    data,
  };
}

export function setCreatedReservedSetting(payload) {
  return {
    type: SET_CREATED_RESERVED_SETTING,
    payload,
  };
}

export function fetchReservedSettingDetailsById(id) {
  return {
    type: FETCH_RESERVED_SETTING_DETAILS_BY_ID,
    id,
  };
}

export function setLoadedReservedSettingDetails(payload) {
  return {
    type: SET_LOADED_RESERVED_SETTING_DETAILS,
    payload,
  };
}

export function fetchReservedSettingTables(storeId) {
  return {
    type: FETCH_RESERVED_TABLES,
    storeId,
  };
}

export function fetchReservedSettingTablesForUpdate(slotId, storeId) {
  return {
    type: FETCH_RESERVED_TABLES_FOR_UPDATE,
    slotId,
    storeId,
  };
}

export function setReservedSettingTables(payload) {
  return {
    type: SET_RESERVED_SETTING_TABLES,
    payload,
  };
}
