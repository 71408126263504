import React, { useState } from "react";
import { List as AntList, Checkbox } from "antd";
import PropTypes from "prop-types";
export default function TableWithSelection({
  options,
  defaultSelectedItems = [],
  valueName = "name",
  label = () => {},
  hasAllSelect = true,
  style = {},
  hasMultipleSelect = true,
  onChangeSelection,
}) {
  const [alreadySelected, setAlreadySelected] = useState(defaultSelectedItems);

  const updatedOptions = hasAllSelect
    ? [{ tableItemId: -2, name: "全店舗", disabled: false }, ...options]
    : options;

  const isChecked = (item) => {
    return (
      alreadySelected.find((o) => o.tableItemId === item.tableItemId) !==
      undefined
    );
  };

  const handleItemSelect = (checked, item) => {
    if (checked && item.tableItemId === -2) {
      handleChangeSelection(updatedOptions.filter((o) => !o.disabled));
    } else if (!checked && item.tableItemId === -2) {
      handleChangeSelection([]);
    } else if (checked) {
      if (hasMultipleSelect) {
        handleChangeSelection([...alreadySelected, item]);
      } else {
        handleChangeSelection([item]);
      }
    } else if (!checked) {
      if (hasMultipleSelect) {
        handleChangeSelection(
          alreadySelected.filter(
            (o) => o.tableItemId !== item.tableItemId && o.tableItemId !== -2
          )
        );
      } else {
        handleChangeSelection([]);
      }
    }
  };

  const handleChangeSelection = (selectedItems) => {
    setAlreadySelected(selectedItems);
    onChangeSelection(selectedItems.filter((o) => o.tableItemId !== -2));
  };

  return (
    <>
      <AntList
        size="small"
        bordered="bordered"
        dataSource={updatedOptions}
        style={style}
        locale={{ emptyText: "該当するデータはありません。" }}
        renderItem={(item, index) => {
          return (
            <AntList.Item
              key={index}
              style={{
                color: item.disabled ? "#eee" : "inherit",
              }}
            >
              <div className="list-custom">
                <Checkbox
                  style={{ width: "100%" }}
                  disabled={item.disabled}
                  onClick={(e) => {
                    handleItemSelect(e.target.checked, item);
                  }}
                  checked={isChecked(item)}
                  name={name}
                >
                  {item[valueName]}
                </Checkbox>
                <div className="multi-checkbox-right">{label(item)}</div>
              </div>
            </AntList.Item>
          );
        }}
      />
    </>
  );
}

TableWithSelection.propTypes = {
  valueName: PropTypes.string,
  itemProps: PropTypes.object,
  options: PropTypes.array,
  defaultSelectedItems: PropTypes.array,
  label: PropTypes.any,
  validation: PropTypes.any,
  hasAllSelect: PropTypes.any,
  style: PropTypes.any,
  hasMultipleSelect: PropTypes.any,
  fullLineClick: PropTypes.bool,
  onChangeSelection: PropTypes.func,
};
