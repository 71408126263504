import { SyncOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./style/index.less";
import { useSelector } from "react-redux";
import { getAwsConfig } from "../../../../utils/common";

const BucketList = ({ companyCode }) => {
  const data = useSelector((state) => state.emailConfigReducer.awsConfig);
  const awsConfig = getAwsConfig(data);
  AWS.config.update({
    accessKeyId: awsConfig?.awsId,
    secretAccessKey: awsConfig?.awsKeySecrect,
    region: awsConfig?.region,
  });
  const s3 = new AWS.S3();
  const [mailContent, setMailContent] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [haveMail, setHaveMail] = useState(true);
  const params = {
    Bucket: "email.production.umat-operation.com",
    Prefix: `reservations/${companyCode}/gnavi_auth`,
  };
  const onReload = () => {
    setMailContent("");
    setIsReload(true);
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        setHaveMail(false);
        setIsReload(false);
      } else {
        let lastMailContent = data.Contents[0];
        console.log(Date.parse(lastMailContent.LastModified));
        for (let i = 0; i < data.Contents.length; i++) {
          if (
            Date.parse(lastMailContent.LastModified) <
            Date.parse(data.Contents[i].LastModified)
          ) {
            lastMailContent = data.Contents[i];
          }
        }

        const param2 = {
          Bucket: "email.production.umat-operation.com",
          Key: lastMailContent?.Key,
        };
        s3.getObject(param2, (err, data) => {
          if (err || data.Body.length === 0) {
            setHaveMail(false);
          } else {
            if (data.Body.toString("utf-8")) {
              let dataTxt = data.Body.toString("utf-8").replace(
                /(https?:\/\/[^\s]+)/g,
                function (url) {
                  return (
                    '<a href="' + url + '" target="_blank">' + url + "</a>"
                  );
                }
              );
              dataTxt = dataTxt.split("MIME-Version: 1.0")[1];
              const dataTextList = dataTxt.split("\n");
              const dataTextList2 = [];
              for (let i = 0; i < dataTextList.length; i++) {
                if (i > 2) {
                  dataTextList2.push("\n");
                  dataTextList2.push(dataTextList[i]);
                }
              }
              dataTxt = dataTextList2.join("");
              const encoder = new TextEncoder("iso-2022-jp");
              const decoder = new TextDecoder("iso-2022-jp");
              const encodedText = encoder.encode(dataTxt);
              const decodedTextList = decoder.decode(encodedText);

              setMailContent(decodedTextList.replace(/^\s+|\s+$/g, ""));
              setHaveMail(true);
            }
          }
        });

        setIsReload(false);
      }
    });
  };

  useEffect(() => {
    onReload();
  }, []);

  return (
    <div className="form-wrapper" style={{ paddingRight: "0px" }}>
      <Row className="form-section">
        <Col md={{ span: 20, offset: 2 }}>
          <div className="registration-email-title">
            <span>
              ぐるなび管理画面にて取込専用メールアドレスを認証してください。
            </span>
          </div>
          <div className="title-confirm-mail">
            <span>
              1. メール本文に記載されている「暗証番号」をコピーしてください。
            </span>
          </div>
          <Row justify="center">
            <div className="mail-form">
              <div className="mail-content-title">
                <button
                  className="reload-mail-button"
                  onClick={() => onReload()}
                >
                  {isReload ? <SyncOutlined spin /> : <SyncOutlined />}
                  メールの再読み込み &nbsp; &nbsp;
                </button>

                <span className="mail-title-span">
                  ※メールが正しく表示されない場合、再読み込みしてください。
                </span>
              </div>
              {haveMail ? (
                <div
                  className="mail-content"
                  dangerouslySetInnerHTML={{ __html: mailContent }}
                />
              ) : (
                <div className="mail-content-fail">
                  <p>
                    <span style={{ display: "inline-block" }}>
                      グルメサイトから認証メール受信に時間がかかっています。
                    </span>
                    <span style={{ display: "inline-block" }}>
                      しばらく時間を置いてからメールの再読み込みを実施してください。
                    </span>
                  </p>
                </div>
              )}
            </div>
          </Row>
          <div className="title-confirm-mail">
            <span>
              2.
              ぐるなび管理画面に、1でコピーした暗証番号を入力し、取込専用メールアドレスの認証をしてください。
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

BucketList.propTypes = {
  companyCode: PropTypes.string,
};

export default BucketList;
