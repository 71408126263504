import React, { useEffect, useState } from "react";
import { sortableHandle } from "react-sortable-hoc";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Col, Row, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Layout from "containers/Layout";
import "containers/StoreMaster/style/index.less";
import DragAndDroppableTable from "components/DragAndDroppableTable";
import EditIcon from "components/Icons/EditIcon";
import TrashIcon from "components/Icons/TrashIcon";
import DragIcon from "components/Icons/DragIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import FormHeader from "components/FormHeader";

import { setLoading } from "actions/common";
import {
  deleteReservedSetting,
  fetchAllReservedSettingList,
  reOrderReservedSettingList,
  setAllReservedSettingList,
  setCreatedReservedSetting,
} from "actions/dianpingSeatSetting";

const { confirm } = Modal;

export default function DianpingReservedSeatSetting() {
  const customStyles = {
    cursor: "pointer",
  };
  const columns = [
    {
      dataIndex: "sort",
      width: 51,
      className: "drag-visible",
      render: function dragHandleMethod() {
        return <DragHandle />;
      },
    },
    {
      title: "店舗管理名称",
      dataIndex: "name",
    },
    {
      title: "対象テーブル",
      dataIndex: "tableType",
    },
    {
      title: "受付可能人数",
      dataIndex: "numberPeople",
    },
    {
      title: "予約受付設定",
      dataIndex: "receivingStatus",
    },
    {
      title: "編集",
      dataIndex: "edit",
      width: 70,
      render: function renderEditIcon(_, record) {
        return dataSource.length >= 1 ? (
          <div onClick={() => editSetting(record.key)} style={customStyles}>
            <EditIcon customeStyles={customStyles} />
          </div>
        ) : null;
      },
    },
    {
      title: "削除",
      dataIndex: "delete",
      width: 70,
      render: function renderDeleteIcon(_, record) {
        return dataSource.length >= 1 ? (
          <div onClick={() => showDeleteConfirm(record)} style={customStyles}>
            <TrashIcon customeStyles={customStyles} />
          </div>
        ) : null;
      },
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  // useSelector
  const reservedSettingList = useSelector(
    (state) => state.dianpingReservedSettingReducer.reservedSettingList
  );
  const selectedStore = useSelector(
    (state) => state.layoutReducer.selectedStore
  );

  // useState
  const [dataSource, setDataSource] = useState([]);
  const [fullDataSource, setFullDataSource] = useState([]);

  useEffect(() => {
    if (!reservedSettingList) {
      dispatch(fetchAllReservedSettingList(selectedStore.id));
    }
    dispatch(setLoading(true));
    dispatch(setCreatedReservedSetting(false));
  }, []);

  useEffect(() => {
    // TODO: Get data from API
    if (reservedSettingList) {
      const formattedData = formatData(reservedSettingList.data);
      setDataSource(formattedData);
      setFullDataSource(formattedData);
      dispatch(setAllReservedSettingList(null));
      dispatch(setCreatedReservedSetting(false));
    }
  }, [reservedSettingList]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setAllReservedSettingList(null));
    dispatch(fetchAllReservedSettingList(selectedStore.id));
  }, [selectedStore]);

  function formatData(data) {
    const newData = [];
    data.forEach((row, index) => {
      newData.push({
        key: row.id,
        name: row.name,
        tableType: row.tableType,
        numberPeople:
          row.minNumberOfCustomer === row.maxNumberOfCustomer
            ? `${row.minNumberOfCustomer}名`
            : `${row.minNumberOfCustomer}～${row.maxNumberOfCustomer}名`,
        receivingStatus:
          row.isReceiving === true ? "受け付ける" : "受け付けない",
        index: index,
      });
    });
    return newData;
  }
  const showDeleteConfirm = (record) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "確認",
      content:
        "削除したデータはもとに戻せません。予約席を削除してもよろしいですか？",
      okText: "はい",
      okType: "danger",
      cancelText: "いいえ",
      centered: true,
      onOk() {
        dispatch(setLoading(true));
        dispatch(deleteReservedSetting(`${record.key}`, selectedStore.id));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const addNew = () => {
    history.push("/settings/dianping-reserved-seat-setting/create");
  };
  const editSetting = (val) => {
    history.push(`/settings/dianping-reserved-seat-setting/edit/${val}`);
  };
  const DragHandle = sortableHandle(() => (
    <DragIcon customStyles={{ cursor: "grab" }} />
  ));
  const handleDataSource = (newData) => {
    setDataSource(newData);
  };
  const reOrder = (data) => {
    const reOrderedData = [];
    data.forEach((row, index) => {
      reOrderedData.push({
        slotId: row.key,
        displayOrder: index + 1,
      });
    });
    dispatch(setLoading(true));
    dispatch(reOrderReservedSettingList(reOrderedData, selectedStore.id));
  };

  return (
    <Layout>
      <div className="list-container">
        <Row style={{ marginBottom: "6.17px" }}>
          <FormHeader
            title={"大衆点評予約席"}
            icon={<SettingsIcon width="28" height="28" />}
          />
        </Row>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "20px" }}
        >
          <Col span={24}>
            <DragAndDroppableTable
              data={dataSource}
              columns={columns}
              addNew={addNew}
              handleDataSource={handleDataSource}
              fullDataSource={fullDataSource}
              reOrder={reOrder}
              emptyText={"条件に該当する予約席はありません"}
              placeholder={"予約席を検索"}
            />
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
