import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table } from "antd";
import { Input, NumberInput, RadioButton } from "components/FormControllers";
import Label from "components/Form/atoms/Label";
import SectionHeader from "components/Form/atoms/SectionHeader";
import TouchableLabel from "./atoms/TouchableLabel";
import SelectTableModal from "../Modal/SelectTableModal";

function DianpingReservedSettingForm({
  control,
  isEdit,
  tables,
  onChangeTables,
  defaultSelectedTables,
}) {
  const [showTableList, setShowTableList] = useState(false);
  const [selectedTables, setSelectedTables] = useState([]);
  const columns = [
    {
      title: "テーブル名",
      key: "name",
      render: (k, row) => {
        return row.name;
      },
    },
    {
      title: "人数",
      key: "numberOfSeats",
      render: (k, row) => {
        return row.numberOfSeats + "名";
      },
    },
  ];
  const receptionSettings = [
    {
      value: "true",
      label: "受け付ける",
    },
    {
      value: "false",
      label: "受け付けない",
    },
  ];
  // use effect
  useEffect(() => {
    if (isEdit) {
      setSelectedTables(defaultSelectedTables);
    }
  }, [defaultSelectedTables]);
  // handler
  const handleOpenTableList = () => {
    setShowTableList(true);
  };
  const handleChangeTables = (tables) => {
    setShowTableList(false);
    setSelectedTables(tables);
    onChangeTables(tables);
  };
  return (
    <div className="form-wrapper">
      <Row className="form-section">
        <Col span={24}>
          <SectionHeader label={"予約席"} />
        </Col>
        <Col span={24}>
          <div className="input-group">
            <Label label={"店舗管理名称"} />
            <div className="input-element">
              <Input
                control={control}
                inputName="name"
                inputProps={{
                  placeholder: "店舗管理名称を入力してください",
                  autoComplete: "off",
                  style: { maxWidth: "100%" },
                }}
              />
            </div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Row justify={"space-between"}>
              <Label label={"対象テーブル"} />
              <TouchableLabel
                label={"テーブルを選択する"}
                onClick={handleOpenTableList}
              />
            </Row>
            <div className="input-element">
              <Table
                dataSource={selectedTables}
                columns={columns}
                pagination={false}
                locale={{ emptyText: "該当するデータはありません。" }}
              />
            </div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <div className="input-group">
            <Label label={"受付可能人数"} required={true} />
            <div className="input-element">
              <Row align={"middle"}>
                <NumberInput
                  control={control}
                  inputName="minNumberOfCustomer"
                  defaultValue={""}
                  inputNumberProps={{
                    min: 0,
                    max: 999,
                    maxLength: 3,
                    placeholder: "1",
                    type: "number",
                  }}
                  label={""}
                  type="text"
                />
                <span className={"span-padding-left-right"}>～</span>
                <NumberInput
                  control={control}
                  inputName="maxNumberOfCustomer"
                  defaultValue={""}
                  inputNumberProps={{
                    min: 0,
                    max: 999,
                    maxLength: 3,
                    placeholder: "4",
                    type: "number",
                  }}
                  label={"名"}
                  type="text"
                />
              </Row>
            </div>
          </div>
        </Col>
        <Col span={24} style={{ marginTop: 25 }}>
          <Label label={"予約受付設定"} required={false} />
          <div
            className="input-element"
            style={{ marginRight: 25, marginTop: 8 }}
          >
            <RadioButton
              control={control}
              defaultValue={receptionSettings[0].value}
              inputName={"isReceiving"}
              buttons={receptionSettings}
              classes={"radio-button-container-flex-start"}
            />
          </div>
        </Col>
      </Row>
      <SelectTableModal
        tables={tables}
        defaultSelectedTables={defaultSelectedTables}
        isModalVisible={showTableList}
        handleCancel={() => setShowTableList(false)}
        handleSubmit={handleChangeTables}
      />
    </div>
  );
}

DianpingReservedSettingForm.propTypes = {
  control: PropTypes.any,
  isEdit: PropTypes.bool,
  tables: PropTypes.array,
  defaultSelectedTables: PropTypes.array,
  onChangeTables: PropTypes.func,
};

export default DianpingReservedSettingForm;
