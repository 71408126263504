import React from "react";
import PropTypes from "prop-types";

function TouchableLabel({ label, isMultiline = false, tooltip, onClick }) {
  return (
    <div onClick={onClick}>
      <span
        className={`input-label-text ${
          isMultiline ? "input-label-text__multiline" : ""
        } touchable-label`}
      >
        {label}
      </span>
      {tooltip && tooltip()}
    </div>
  );
}

TouchableLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isMultiline: PropTypes.bool,
  tooltip: PropTypes.any,
  onClick: PropTypes.func,
};

export default TouchableLabel;
