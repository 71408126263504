import produce from "immer";
import { SET_ALL_RESERVED_SETTING_LIST } from "constant";

const initialState = {
  reservedSettingList: null,
};

const dianpingReservedSettingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_ALL_RESERVED_SETTING_LIST:
        draft.reservedSettingList = action.payload;
        break;
      default:
        break;
    }
  });

export default dianpingReservedSettingReducer;
