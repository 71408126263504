import produce from "immer";
import {
  SET_CREATED_RESERVED_SETTING,
  SET_LOADED_RESERVED_SETTING_DETAILS,
  SET_RESERVED_SETTING_TABLES,
} from "constant";

const initialState = {
  isCreatedSetting: false,
  loadedSettingDetails: null,
  reservedSettingTables: null,
};

const dianpingReservedSettingCreateUpdateReducer = (
  state = initialState,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CREATED_RESERVED_SETTING:
        draft.isCreatedSetting = action.payload;
        break;
      case SET_LOADED_RESERVED_SETTING_DETAILS:
        draft.loadedSettingDetails = action.payload;
        break;
      case SET_RESERVED_SETTING_TABLES:
        draft.reservedSettingTables = action.payload;
        break;
      default:
        break;
    }
  });

export default dianpingReservedSettingCreateUpdateReducer;
