import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Input } from "antd";
import smokingHeated from "../../assets/smoking-heated.svg";
import nonSmokeIcon from "../../assets/non-smoke.svg";
import smokeIcon from "../../assets/smoke.svg";
import SearchIcon from "../Icons/SearchIcon";
import TableWithSelection from "../TableWithSelection/TableWithSelection";
import { setError } from "../../actions/common";
import { useDispatch } from "react-redux";

function SelectTableModal({
  isModalVisible,
  tables,
  defaultSelectedTables,
  handleCancel,
  handleSubmit,
}) {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [selectedTables, setSelectedTables] = useState([]);

  useEffect(() => {
    if (defaultSelectedTables) {
      setSelectedTables(defaultSelectedTables);
    }
  }, [defaultSelectedTables]);

  const listData = useMemo(() => {
    if (keyword && keyword.length > 0) {
      return tables.filter((entry) =>
        entry.name.toLowerCase().includes(keyword.toLowerCase())
      );
    }
    return tables;
  }, [tables, keyword]);
  const listHeight = useMemo(() => {
    const height = Math.min(listData.length * 39 + 2, window.innerHeight * 0.5);
    return Math.max(height, 197);
  }, [listData]);
  const onClickSubmit = () => {
    if (selectedTables.length > 0) {
      handleSubmit(selectedTables);
    } else {
      dispatch(setError("テーブルを選択してください"));
    }
  };
  const renderButtons = [
    <div key={"buttons"} className={"bottom-buttons"}>
      <Button key="cancel" onClick={handleCancel} className={"cancel-button"}>
        キャンセル
      </Button>
      <Button
        key="click"
        onClick={onClickSubmit}
        type="primary"
        className={"submit-button"}
      >
        決定
      </Button>
    </div>,
  ];
  const renderListItem = (item) => {
    if (item.numberOfSeats === -1 || item.id === -1) {
      return null;
    }
    return (
      <div style={{ display: "flex" }}>
        <span
          style={{
            paddingRight: "5px",
            whiteSpace: "nowrap",
          }}
        >
          {item.numberOfSeats}名席
        </span>
        <span style={{ width: 25 }}>
          {item.numberOfSeats > -1 ? (
            item.smokeStatus === 2 ? (
              <img src={smokingHeated} style={{ width: 17 }} alt={""} />
            ) : item.smokeStatus === 1 ? (
              <img src={nonSmokeIcon} alt={""} />
            ) : item.smokeStatus === 0 ? (
              <img src={smokeIcon} alt={""} />
            ) : null
          ) : null}
        </span>
      </div>
    );
  };
  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={renderButtons}
      title={"テーブル選択"}
      className="select-table-modal"
    >
      <div
        style={{
          width: "100%",
          height: 40,
          marginBottom: 16,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Input
          placeholder={"テーブルを検索"}
          value={keyword}
          onChange={(e) => {
            const currValue = e.target.value;
            setKeyword(currValue);
          }}
          suffix={<SearchIcon />}
          className={"search-box"}
        />
      </div>
      <TableWithSelection
        options={listData}
        hasAllSelect={false}
        style={{ height: listHeight }}
        label={renderListItem}
        defaultSelectedItems={selectedTables}
        onChangeSelection={(items) => {
          setSelectedTables(items);
        }}
      />
    </Modal>
  );
}

SelectTableModal.propTypes = {
  isModalVisible: PropTypes.bool,
  tables: PropTypes.any,
  defaultSelectedTables: PropTypes.array,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.any,
};

export default SelectTableModal;
