import { Col, Row } from "antd";
import Label from "components/Form/atoms/Label";
import SectionHeader from "components/Form/atoms/SectionHeader";
import PropTypes from "prop-types";
import React from "react";
import { Input, RadioButton } from "../FormControllers";
import {
  // COMMON_SELECTION_OPTIONS,
  // DIANPING_MANAGE_BY_PEOPLE,
  DIANPING_STOCKUNITS,
  DIANPING_STOCKUNITS_MODES,
} from "../../constant";
// import PeriodDatePicker from "./PeriodDatePicker";
// import BusinessTimePicker from "./BusinessTimePicker";
// import DayBusinessTimePicker from "./DayBusinessTimePicker";

export default function DianpingLinkageStoreMaterForm({
  control,
  stockUnit = DIANPING_STOCKUNITS_MODES.TIME,
  onValidateBusinessTime,
}) {
  return (
    <Row className="form-section" style={{ marginTop: "30px" }}>
      <Col span={24}>
        <SectionHeader label={"大衆点評連携設定"} />
      </Col>
      <Col span={24}>
        <div className="input-group">
          <Label label={"店舗ID"} />
          <div className="input-element">
            <Input
              control={control}
              inputName="shopId"
              defaultValue={""}
              inputProps={{
                placeholder: "店舗IDを入力してください",
                style: {
                  autoComplete: "off",
                  minWidth: 280,
                  width: "100%",
                },
              }}
              label={""}
              noSpecialChar={true}
            />
          </div>
        </div>
      </Col>
      {/* <PeriodDatePicker control={control} /> */}
      <Col span={24} style={{ marginTop: 12 }}>
        <div className="input-group">
          <Label label={"在庫単位"} required={false} />
          <div className="input-element">
            <RadioButton
              control={control}
              defaultValue={stockUnit}
              inputName={"stockUnit"}
              buttons={DIANPING_STOCKUNITS}
              isFlat={false}
            />
          </div>
        </div>
      </Col>
      {/* {stockUnit === DIANPING_STOCKUNITS_MODES.TIME ? ( */}
      {/*  <BusinessTimePicker */}
      {/*    control={control} */}
      {/*    onValidateBusinessTime={onValidateBusinessTime} */}
      {/*  /> */}
      {/* ) : ( */}
      {/*  <Col span={24}> */}
      {/*    <div className="input-group"> */}
      {/*      <Label label={"skuid"} /> */}
      {/*      <div className="input-element"> */}
      {/*        <Input */}
      {/*          control={control} */}
      {/*          inputName="skuid" */}
      {/*          inputProps={{ */}
      {/*            placeholder: "skuidを入力してください", */}
      {/*            autoComplete: "off", */}
      {/*            style: { maxWidth: 250 }, */}
      {/*          }} */}
      {/*        /> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </Col> */}
      {/* )} */}
      {/* {stockUnit === DIANPING_STOCKUNITS_MODES.DAY && ( */}
      {/*  <DayBusinessTimePicker control={control} /> */}
      {/* )} */}
      {/* <Col span={24} style={{ marginTop: 12 }}> */}
      {/*  <div className="input-group"> */}
      {/*    <Label label={"在庫を人数で管理"} required={false} /> */}
      {/*    <div className="input-element"> */}
      {/*      <RadioButton */}
      {/*        control={control} */}
      {/*        defaultValue={COMMON_SELECTION_OPTIONS.YES} */}
      {/*        inputName={"manageByPeople"} */}
      {/*        buttons={DIANPING_MANAGE_BY_PEOPLE} */}
      {/*        isFlat={false} */}
      {/*      /> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </Col> */}
    </Row>
  );
}

DianpingLinkageStoreMaterForm.propTypes = {
  control: PropTypes.any,
  stockUnit: PropTypes.string,
  onValidateBusinessTime: PropTypes.func,
  onDateChange: PropTypes.func,
};
